import React from "react";
import Sidebar from "../../components/layouts/sidebar/Sidebar";
import "./Homepage.css"
import { Routes, Route } from "react-router-dom";
const Dashboard = React.lazy(() => import("../Dashboard/Dashboard"))
const Institutemanagement = React.lazy(() => import("../Institutemanagement/Institutemanagement"))
const Header = React.lazy(() => import("../../components/layouts/header/Header"))
const Staffinformation = React.lazy(() => import("../../components/Reuseablecomponents/Staffinformation/Staffinformation"))
const Studentinformation = React.lazy(() => import("../../components/Reuseablecomponents/Studentinformation/Studentinformation"))
const AddNewStudentProfile = React.lazy(() => import("../../components/Reuseablecomponents/Addnewstudent/AddNewStudentProfile"))
const Studentdetails = React.lazy(() => import("../../components/Reuseablecomponents/Studentdetails/Studentdetails"))
const Staffdetails = React.lazy(() => import("../../components/Reuseablecomponents/Staffdetails/Staffdetails"))
const Addnewteacherprofile = React.lazy(() => import("../../components/Reuseablecomponents/Addnewteacher/Addnewteacher"))
const Usermanagement = React.lazy(() => import("../../components/Reuseablecomponents/Usermanagement/Usermanagement"))
const Bulkupload = React.lazy(() => import("../../components/Reuseablecomponents/Bulkupload/Bulkupload"))
const Classroommanagement = React.lazy(() => import("../Classroommanagement/Classroommanagement"))


export default function Homepage() {

    return (
        <>

            {/* Homepage start */}

            <div className="homepage_container">
                <div className="row m-0 vh-100">
                    <Sidebar />

                    <div className="homepage_tab_content p-0 vh-100">

                        <Header />

                        <Routes>
                            {/* dashborad */}

                            <Route path="/" index element={<React.Suspense fallback={<></>}>
                                <Dashboard />
                            </React.Suspense>
                            } />

                            <Route path="/dashboard" element={<React.Suspense fallback={<></>}>
                                <Dashboard />
                            </React.Suspense>} />

                            {/* institutemanagement */}

                            <Route path="/institutemanagement" element={<React.Suspense fallback={<></>}>
                                <Institutemanagement />
                            </React.Suspense>} >


                                {/* Student information */}

                                <Route index element={<React.Suspense fallback={<></>}>
                                    <Studentinformation />
                                </React.Suspense>} />

                                <Route path="/institutemanagement/studentinformation" index element={<React.Suspense fallback={<></>}>
                                    <Studentinformation />
                                </React.Suspense>} />

                                {/* Staff information */}

                                <Route path="/institutemanagement/staffinformation" element={<React.Suspense fallback={<></>}>
                                    <Staffinformation />
                                </React.Suspense>} />

                                {/* Student details */}

                                <Route path="/institutemanagement/studentinformation/:id" element={<React.Suspense fallback={<></>}>
                                    <Studentdetails />
                                </React.Suspense>} />

                                {/* Staff details */}

                                <Route path="/institutemanagement/staffinformation/:id" element={<React.Suspense fallback={<></>}>
                                    <Staffdetails />
                                </React.Suspense>} />

                                {/* User management */}

                                <Route path="/institutemanagement/usermanagement" element={<React.Suspense fallback={<></>}>
                                    <Usermanagement />
                                </React.Suspense>} />

                                {/* Bulk upload */}

                                <Route path="/institutemanagement/bulkupload" element={<React.Suspense fallback={<></>}>
                                    <Bulkupload />
                                </React.Suspense>} />


                            </Route>


                            {/* add new student */}

                            <Route path="/institutemanagement/addnewstudent" element={<React.Suspense fallback={<></>}>
                                <AddNewStudentProfile />
                            </React.Suspense>} />

                            {/* Add new teacher */}

                            <Route path="/institutemanagement/addnewteacher" element={<React.Suspense fallback={<></>}>
                                <Addnewteacherprofile />
                            </React.Suspense>} />

                            {/* classroom management */}

                            <Route path="/classroommanagement" element={<React.Suspense fallback={<></>}>
                                <Classroommanagement />
                            </React.Suspense>} >
                                <Route path="/classroommanagement/classrooms" element={<React.Suspense fallback={<></>}>
                                    <Classroommanagement />
                                </React.Suspense>} />
                            </Route>

                        </Routes>
                    </div>

                </div>
            </div>

            {/* Homepage over */}

        </>
    )
}