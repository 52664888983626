import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import "./Sidebar.css";

export default function Sidebar() {

  const path = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  return (
    <>

      {/* Sidebar css start */}

      <div className="left_sidebar p-0">
        <div className="brand_heading d-flex justify-content-center align-items-center">
          <img src="/assets/images/school-logo.png" alt="school-logo" className="school_logo_image" />
          <h3 className="school_name">Shri John Matric school</h3>
        </div>
        <div className="left_navlist">
          {/* <!-- Nav tabs --> */}
          <ul className="nav nav-tabs">
            <li className={`nav-item ${((path.pathname === "/dashboard" || path.pathname === "/")) ? 'active' : ''}`} data-bs-dismiss="offcanvas">
              <Link className="nav-link" to="/dashboard">
                <img src="/assets/images/dashboard.png" alt="dashboard" className="navlink_image" />
                <span className="heading_left_sidebar_link">Dashboard</span>
              </Link>
            </li>
            <li className={`nav-item ${((path.pathname.substring(0, 20) === "/institutemanagement")) ? 'active' : ''}`} data-bs-dismiss="offcanvas">
              <Link className="nav-link" to="/institutemanagement">
                <img src="/assets/images/intitute-mgmt.png" alt="InstituteManagement" className="navlink_image" />
                <span className="heading_left_sidebar_link">
                  Institute Management
                </span>
              </Link>
            </li>
            <li className={`nav-item ${((path.pathname.substring(0, 20) === "/classroommanagement")) ? 'active' : ''}`}>
              <Link className="nav-link" to="/classroommanagement">
                <img src="/assets/images/classroom-mgmt.png" alt="classroommanagement" className="navlink_image" />
                <span className="heading_left_sidebar_link">
                  Classroom Management
                </span>
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" data-bs-toggle="tab" to="/Academics">
                <img src="/assets/images/acedemic.png" alt="Academics" className="navlink_image" />
                <span className="heading_left_sidebar_link">Academics</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" data-bs-toggle="modal" data-bs-target="#exampleModal" to="/FeesManagement">
                <img src="/assets/images/fee-mgmt.png" alt="FeesManagement" className="navlink_image" />
                <span className="heading_left_sidebar_link">Fees Management</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" data-bs-toggle="modal" data-bs-target="#exampleModal" to="/ExamsManagement">
                <img src="/assets/images/exam-mgmt.png" alt="ExamsManagement" className="navlink_image" />
                <span className="heading_left_sidebar_link">
                  Exams Management
                </span>
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" data-bs-toggle="modal" data-bs-target="#exampleModal" to="/AdmissionManagement">
                <img src="/assets/images/admission-mgmt.png" alt="AdmissionManagement" className="navlink_image" />
                <span className="heading_left_sidebar_link">
                  Admission Management
                </span>
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" data-bs-toggle="modal" data-bs-target="#exampleModal" to="/HomeworkAssignment">
                <img src="/assets/images/homeword&assignment.png" alt="HomeworkAssignment" className="navlink_image" />
                <span className="heading_left_sidebar_link">
                  Homework & Assignment
                </span>
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" data-bs-toggle="modal" data-bs-target="#exampleModal" to="/InsightfulStatistics">
                <img src="/assets/images/insightful.png" alt="InsightfulStatistics" className="navlink_image" /><span className="heading_left_sidebar_link">
                  Insightful Statistics
                </span>
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" data-bs-toggle="modal" data-bs-target="#exampleModal"  to="/CustomReports">
                <img src="/assets/images/custom-report.png" alt="CustomReports" className="navlink_image" />
                <span className="heading_left_sidebar_link">Custom Reports</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" data-bs-toggle="modal" data-bs-target="#exampleModal"  to="/Administration">
                <img src="/assets/images/administration.png" alt="Administration" className="navlink_image" /><span className="heading_left_sidebar_link">Administration</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" data-bs-toggle="modal" data-bs-target="#exampleModal" to="/HRMS">
                <img src="/assets/images/HRMS.png" alt="HRMS" className="navlink_image" />
                <span className="heading_left_sidebar_link">HRMS</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" data-bs-toggle="modal" data-bs-target="#exampleModal" to="/Facilities">
                <img src="/assets/images/facilities.png" alt="Facilities" className="navlink_image" />
                <span className="heading_left_sidebar_link">Facilities</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" data-bs-toggle="tab" to="/Alumni">
                <img src="/assets/images/alumni.png" alt="Alumni" className="navlink_image" />
                <span className="heading_left_sidebar_link">Alumni</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/AccountSettings">
                <img src="/assets/images/account-settings.png" alt="AccountSettings" className="navlink_image" /><span className="heading_left_sidebar_link">
                  Account Settings
                </span>
              </Link>
            </li>
          </ul>

        </div>
        <div className="poweredby_qualify">
          <img src="/assets/images/poweredby-qualify.png" alt="poweredbyqualify" className="powered_by_qual" />
        </div>
      </div>

      <div className="modal fade upgrade_modal" id="exampleModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel"></h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className="upgrade_icon">
                <img src="/assets/images/upgrade_icon.png" alt="upgrade_icon" />
              </div>
              <p className="upgrade_text">
                Upgrade to advanced plan to access these features. Send an email to <Link to={"mailto:reachus@qqualifi.com"}>reachus@qqualifi.com.</Link>
              </p>

              <div className="modal-footer">
                <button className="btn upgrade_now_btn">
                  upgrade now
                </button>
                <button className="btn upgrade_now_btn upgrade_later_btn" data-bs-dismiss="modal">
                  later
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Sidebar css over */}

    </>
  );
}
