import React from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "../src/assets/plugins/fontawesome-free/css/all.min.css";
import Homepage from "./pages/Homepage/Homepage";
import { BrowserRouter } from "react-router-dom";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";

function App() {



  return (
    <>
      <div className="wrapper">
        <BrowserRouter>
        
        <Homepage />
        </BrowserRouter>
      </div>
    </>
  );
}

export default App;
